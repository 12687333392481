// ConversationList.js
import React, { useState } from 'react';
import {
  Box,
  VStack,
  Flex,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon, HamburgerIcon } from '@chakra-ui/icons';
import CollectionSelector from './../CollectionSelector'; // Import the reusable component

const ConversationList = ({
  conversations,
  fetchConversation,
  handleNewConversation,
  handleRename,
  handleDelete,
  collapsed,
  collections, // Available collections passed as prop
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCollections, setSelectedCollections] = useState([]);
  const toast = useToast();

  const handleCreateConversation = () => {
    if (selectedCollections.length > 0) {
      const collectionIds = selectedCollections.map(
        (collection) => collection.collection_id
      );
      handleNewConversation(collectionIds);
      setSelectedCollections([]);
      onClose();
      toast({
        title: "New conversation started.",
        description: `Using collections: ${selectedCollections
          .map((c) => c.collection_name)
          .join(", ")}`,
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "No collections selected.",
        description: "Please select at least one collection to start a conversation.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      width={collapsed ? '0' : '19rem'}
      bg="#1c1c1c"
      overflow="hidden"
      transition="width 0.3s"
      padding={collapsed ? "0rem" : "1rem"}
    >
      {!collapsed && (
        <VStack spacing={4}>
          <Button
            onClick={onOpen} // Open modal instead of directly handling new conversation
            colorScheme="red"
            bg="red.600"
            _hover={{ bg: "red.700" }}
            width="full"
            color="white"
          >
            New Conversation
          </Button>

          {/* Modal for selecting collections */}
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg="#232121" color="white">
              <ModalHeader>Select Collections for New Conversation</ModalHeader>
              <ModalCloseButton color="white" _hover={{ bg: "red.600" }} />
              <ModalBody>
                <CollectionSelector
                  availableCollections={collections}
                  selectedCollections={selectedCollections}
                  setSelectedCollections={setSelectedCollections}
                  placeholder="Search collections"
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="ghost"
                  onClick={onClose}
                  color="white"
                  _hover={{ bg: "gray.800" }}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="green"
                  onClick={handleCreateConversation}
                  ml={3}
                  isDisabled={selectedCollections.length === 0}
                >
                  Start Conversation
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {conversations.map((item) => (
            <Flex key={item.conversation_id} width="100%" alignItems="center">
              <Box flex="1">
                <Button
                  flex="1"
                  justifyContent="flex-start"
                  onClick={() => fetchConversation(item.conversation_id)}
                  whiteSpace="nowrap"
                  maxWidth="21rem"
                  textAlign="left"
                  bg="#1c1c1c"
                  color="white"
                  _hover={{ bg: "gray.800" }}
                >
                  {item.first_question.length > 20
                    ? `${item.first_question.substring(0, 20)}...`
                    : item.first_question}
                </Button>
              </Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<HamburgerIcon />}
                  variant="outline"
                  aria-label="Options"
                  color="white"
                  bg="rgb(0 0 0 / 17%)"
                  _hover={{ bg: "gray.800" }}
                />
                <MenuList bg="#1c1c1c" borderColor="gray.700">
                  <MenuItem
                    icon={<EditIcon />}
                    onClick={() => handleRename(item.conversation_id)}
                    _hover={{ bg: "red.600", color: "rgba(0, 0, 0, 0.9)" }}
                    background={"#1c1c1c"}
                    color={"white"}
                  >
                    Rename
                  </MenuItem>
                  <MenuItem
                    icon={<DeleteIcon />}
                    onClick={() => handleDelete(item.conversation_id)}
                    _hover={{ bg: "red.600", color: "rgba(0, 0, 0, 0.9)" }}
                    background={"#1c1c1c"}
                    color={"white"}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default ConversationList;
