// MessagesDisplay.jsx
import React, { useEffect, useRef } from "react";
import { VStack, HStack, Box, Avatar } from "@chakra-ui/react";
import { FaUser } from "react-icons/fa"; // User icon
import { motion } from "framer-motion"; // For animation effects

// Define motion components
const MotionBox = motion(Box);

// Animation Variants
const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.05, // Controls typing speed
    },
  },
};

const childVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

// BotMessage Component for Typing Effect with proper word wrapping
const BotMessage = ({ content }) => {
  const characters = content.split(""); // Split content into individual characters

  return (
    <MotionBox
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      display="inline"
      style={{
        overflowWrap: "break-word",
        wordBreak: "break-word",
        whiteSpace: "normal",
      }}
    >
      {characters.map((char, index) => (
        <MotionBox
          key={index}
          as="span"
          variants={childVariants}
        >
          {char}
        </MotionBox>
      ))}
    </MotionBox>
  );
};

const MessagesDisplay = ({ messages, isTyping, botDetails }) => {
  const messagesEndRef = useRef(null);

  // Scroll to the bottom when a new message is added or typing starts
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isTyping]);

  return (
    <VStack
      flex="1"
      overflowY="auto" // Make the messages area scrollable
      spacing={4}
      bg="white"
      width="100%"
      paddingX="4"
      paddingTop="6" // Added padding to move messages down
    >
      {messages.map((msg, index) => (
        <HStack
          key={index}
          alignSelf={msg.role === "user" ? "flex-end" : "flex-start"}
          bg={msg.role === "user" ? "blue.500" : "gray.100"}
          color={msg.role === "user" ? "white" : "black"}
          borderRadius="lg"
          p={3}
          maxWidth="75%"
          boxShadow="md"
          flexDirection={msg.role === "user" ? "row-reverse" : "row"}
          alignItems="flex-start"
          spacing={2}
        >
          {/* Avatar */}
          {msg.role === "user" ? (
            <Avatar
              icon={<FaUser />}
              bg="blue.600"
              color="white"
              size="sm"
              mt={1}
            />
          ) : (
            <Avatar
              size="sm"
              src={
                botDetails?.icon_base64
                  ? `data:image/png;base64,${botDetails.icon_base64}`
                  : undefined
              }
              name="Bot"
              mt={1}
            />
          )}

          {/* Message Content */}
          <Box
            bg={msg.role === "user" ? "blue.500" : "gray.100"}
            color={msg.role === "user" ? "white" : "black"}
            borderRadius="lg"
            p={2}
            maxWidth="100%"
            style={{
              overflowWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "normal",
            }}
          >
            {msg.role === "user" ? (
              <Box as="span">{msg.content}</Box>
            ) : (
              <BotMessage content={msg.content} />
            )}
          </Box>
        </HStack>
      ))}

      {/* Typing Indicator */}
      {isTyping && (
        <HStack
          alignSelf="flex-start"
          bg="gray.100"
          borderRadius="lg"
          p={3}
          maxWidth="75%"
          boxShadow="md"
          alignItems="center"
          spacing={2}
        >
          <Avatar
            size="sm"
            src={
              botDetails?.icon_base64
                ? `data:image/png;base64,${botDetails.icon_base64}`
                : undefined
            }
            name="Bot"
            mt={1}
          />
          {/* Three dot typing indicator */}
          <HStack>
            <MotionBox
              width="8px"
              height="8px"
              bg="gray"
              borderRadius="50%"
              animate={{
                opacity: [0.4, 1, 0.4],
                y: [0, -5, 0],
              }}
              transition={{
                duration: 1,
                repeat: Infinity,
                ease: "easeInOut",
                delay: 0,
              }}
            />
            <MotionBox
              width="8px"
              height="8px"
              bg="gray"
              borderRadius="50%"
              animate={{
                opacity: [0.4, 1, 0.4],
                y: [0, -5, 0],
              }}
              transition={{
                duration: 1,
                repeat: Infinity,
                ease: "easeInOut",
                delay: 0.2,
              }}
            />
            <MotionBox
              width="8px"
              height="8px"
              bg="gray"
              borderRadius="50%"
              animate={{
                opacity: [0.4, 1, 0.4],
                y: [0, -5, 0],
              }}
              transition={{
                duration: 1,
                repeat: Infinity,
                ease: "easeInOut",
                delay: 0.4,
              }}
            />
          </HStack>
        </HStack>
      )}

      {/* Scroll to the latest message */}
      <div ref={messagesEndRef} />
    </VStack>
  );
};

export default MessagesDisplay;
