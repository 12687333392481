import React, { useState } from "react";
import {
  Box,
  VStack,
  Flex,
  Button,
  Image,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon, HamburgerIcon } from "@chakra-ui/icons";

const BotsList = ({
  bots,
  fetchBotConversation,
  handleDeleteBot,
  handleEditBot,
  handleNewBot,
  collapsed,
  handleGenerateEmbedCode,
  handleDeleteEmbedCode,
  fetchEmbedCode,  // Fetch embed code from parent
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedBot, setSelectedBot] = useState(null);
  const [embedCode, setEmbedCode] = useState(null); // Store the embed code
  const toast = useToast();

  const openEmbedModal = (bot) => {
    setSelectedBot(bot);
    onOpen();
    // Fetch the existing embed code when opening the modal
    fetchEmbedCode(bot.bot_token)
      .then((response) => {
        if (response.data.embed_code) {
          setEmbedCode(response.data.embed_code); // Set the fetched embed code
        } else {
          setEmbedCode(null); // No embed code found
        }
      })
      .catch((error) => {
        console.error("Error fetching embed code:", error);
        toast({
          title: "Error",
          description: "Failed to fetch embed code.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleDeleteEmbed = () => {
    handleDeleteEmbedCode(selectedBot.bot_token, () => {
      setEmbedCode(null); // Reset the embed code after deletion
      toast({
        title: "Embed code deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  const handleCopyEmbedCode = () => {
    navigator.clipboard.writeText(embedCode).then(() => {
      toast({
        title: "Embed code copied to clipboard.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  return (
    <Box
      width={collapsed ? "0" : "19rem"}
      bg="#1c1c1c"
      overflow="hidden"
      transition="width 0.3s"
      padding={collapsed ? "0rem" : "1rem"}
    >
      {!collapsed && (
        <VStack spacing={4}>
          <Button
            onClick={handleNewBot}
            colorScheme="red"
            bg="red.600"
            _hover={{ bg: "red.700" }}
            width="full"
            color="white"
          >
            New Bot
          </Button>
          {bots.map((bot) => (
            <Flex key={bot.bot_token} width="100%" alignItems="center">
              <Image
                boxSize="40px"
                src={`data:image/jpeg;base64,${bot.icon_base64}`}
                alt={`${bot.bot_name} icon`}
                mr={2}
              />
              <Box flex="1">
                <Button
                  flex="1"
                  justifyContent="flex-start"
                  onClick={() => fetchBotConversation(bot.bot_token)}
                  whiteSpace="nowrap"
                  maxWidth="21rem"
                  textAlign="left"
                  bg="#1c1c1c"
                  color="white"
                  _hover={{ bg: "gray.800" }}
                >
                  {bot.bot_name.length > 20
                    ? `${bot.bot_name.substring(0, 20)}...`
                    : bot.bot_name}
                </Button>
              </Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<HamburgerIcon />}
                  variant="outline"
                  aria-label="Options"
                  color="white"
                  bg="rgb(0 0 0 / 17%)"
                  _hover={{ bg: "gray.800" }}
                />
                <MenuList bg="#1c1c1c" borderColor="gray.700">
                  <MenuItem
                    icon={<EditIcon />}
                    onClick={() => handleEditBot(bot.bot_token)}
                    _hover={{ bg: "red.600", color: "rgba(0, 0, 0, 0.9)" }}
                    background={"#1c1c1c"}
                    color={"white"}
                  >
                    Edit Bot
                  </MenuItem>
                  <MenuItem
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteBot(bot.bot_token)}
                    _hover={{ bg: "red.600", color: "rgba(0, 0, 0, 0.9)" }}
                    background={"#1c1c1c"}
                    color={"white"}
                  >
                    Delete Bot
                  </MenuItem>
                  <MenuItem
                    onClick={() => openEmbedModal(bot)}
                    _hover={{ bg: "gray.800" }}
                    background={"#1c1c1c"}
                    color={"white"}
                  >
                    Embed this Bot
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          ))}
        </VStack>
      )}

      {/* Embed Code Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Embed this Bot</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {embedCode ? (
              <>
                <Input value={embedCode} isReadOnly />
                <Button mt={4} colorScheme="blue" onClick={handleCopyEmbedCode}>
                  Copy Embed Code
                </Button>
                <Button mt={4} colorScheme="red" onClick={handleDeleteEmbed}>
                  Delete Embed Code
                </Button>
              </>
            ) : (
              <Button
                mt={4}
                colorScheme="blue"
                onClick={() =>
                  handleGenerateEmbedCode(selectedBot.bot_token, setEmbedCode)
                }
              >
                Generate Embed Code
              </Button>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default BotsList;
