// src/apiService.js

import axios from "axios";
import store from "./store/store"; // Import the Redux store to get the token

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.token;

    // Skip the token for searchWithBotToken API calls
    if (!config.url.includes("/bot/search_with_bot_token") && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Authentication APIs
export const login = (credentials) => api.post("/auth/login", credentials);
export const register = (data) => api.post("/auth/register", data);
export const microsoftLogin = () => api.get("/auth/login/microsoft");
export const authCallback = (params) => api.get("/auth/callback", { params });

// Collections APIs

// Get all private collections created by the user
export const getPrivateCollections = () =>
  api.get("/collections/list_collections");

// Create a new private collection
export const createPrivateCollection = (data) =>
  api.post("/collections/create", data);

// Delete a private collection by ID
export const deletePrivateCollection = (collectionId) =>
  api.delete("/collections/delete_collection", {
    params: { collection_id: collectionId },
  });

// Get all public collections
export const getPublicCollections = () =>
  api.get("/collections/public/collections");

// Create a new public collection (admin only)
export const createPublicCollection = (data) =>
  api.post("/collections/public/create_collection", data);

// Upload documents to a private collection
export const uploadDocument = (formData) =>
  api.post("/collections/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
// Process a document by its ID
export const processDocument = (data) => {
  return api.post("/collections/process", data);
};
// Upload JSON data containing organization, user, and data
export const uploadJsonData = (data) =>
  api.post("/collections/upload_json", data);

// Upload a public document to the collection (admin only)
export const uploadPublicDocument = (formData) =>
  api.post("/collections/upload_public_document", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

// Delete documents from a private collection by document IDs
export const deleteDocuments = (documentIds) => {
  return api.request({
    method: "DELETE",
    url: "/collections/delete_documents",
    data: { document_ids: documentIds },
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Delete a public document by document ID (admin only)
export const deletePublicDocument = (documentId) =>
  api.delete("/collections/delete_public_document", {
    params: { document_id: documentId },
  });

// List private documents in a specified collection
export const getDocumentsByCollection = (collectionId) =>
  api.get("/collections/list_private_documents", {
    params: { collection_id: collectionId },
  });

// List all public documents
export const listPublicDocuments = () =>
  api.get("/collections/list_public_document");

// Search public documents based on a query
export const searchPublicDocuments = (query) =>
  api.get("/collections/search", {
    params: { query },
  });

// Bot Management APIs

// Fetch all bot tokens created by the authenticated user
export const getBotTokens = () => api.get("/bot/get_bot_tokens");

// Generate a new bot token
export const generateBotToken = (formData) =>
  api.post("/bot/generate_bot_token", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
// Generate embed code for a bot
export const generateEmbedCode = (payload) =>
  api.post("/bot/generate_embed_code", payload);

// Delete embed code for a bot
export const deleteEmbedCode = (botToken) =>
  api.request({
    method: "DELETE",
    url: "/bot/delete_embed_code",
    data: { bot_token: botToken },
    headers: {
      "Content-Type": "application/json",
    },
  });
// Fetch embed code for a bot using bot token
export const fetchEmbedCode = (botToken) =>
  api.get("/bot/get_embed_code", {
    params: { bot_token: botToken },
  });

// Get details of a specific bot token
export const getBotTokenDetails = (bot_token) =>
  api.get("/bot/get_bot_token_details", {
    params: { bot_token },
  });

// Update a bot token
export const updateBotToken = (formData) =>
  api.put("/bot/update_bot_token", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
// Delete a bot token
export const deleteBotToken = (botToken) =>
  api.request({
    method: "DELETE",
    url: "/bot/delete_bot_token",
    data: { bot_token: botToken },
    headers: {
      "Content-Type": "application/json",
    },
  });

// Search using a bot token
export const searchWithBotToken = (data) =>
  api.post(
    "/bot/search_with_bot_token",
    {
      query: data.query,
    },
    {
      headers: {
        Authorization: data.bot_token,
        "Content-Type": "application/json",
      },
    }
  );

// Conversation APIs

// Retrieve all conversations for the authenticated user
export const getAllConversations = () =>
  api.get("/conversations/get_all_conversations");

// Retrieve a specific conversation by ID
export const getConversation = (conversation_id) =>
  api.get("/conversations/get_conversation", {
    params: { conversation_id },
  });

// Retrieve conversations for a specific collection ID
export const getConversationsByCollection = (collection_id) =>
  api.get("/conversations/get_conversations_by_collection", {
    params: { collection_id },
  });

// Share a collection with a user by username or email, along with the selected role
export const shareCollectionWithUser = (collectionId, sharedUser, role) => {
  return api.post("/collections/share", {
    collection_id: collectionId,
    user: sharedUser, // This could be a username or email
    role: role, // Add the role being sent along with the user
  });
};

// Search with a conversation
// apiService.js
export const searchWithConversation = (payload) => {
  return api.post("/conversations/search_with_conversation", payload);
};

// Delete a conversation by its ID
export const deleteConversation = (conversation_id) =>
  api.delete("/conversations/delete_conversation", {
    params: { conversation_id },
  });
// Get the users shared with a collection
export const getSharedUsers = (collectionId) =>
  api.get("/collections/list_shared_users", {
    params: { collection_id: collectionId },
  });

// Remove a shared user from a collection
export const removeSharedUser = (collectionId, sharedUser) =>
  api.post("/collections/remove_shared_user", {
    collection_id: collectionId,
    shared_user: sharedUser,
  });
