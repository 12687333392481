import React, { useCallback, useState, useEffect } from 'react';
import {
  Button, FormControl, FormLabel, Modal,
  ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Box, Text, IconButton, Input, Tabs, TabList, TabPanels, Tab, TabPanel, Select, useToast
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { CloseIcon } from '@chakra-ui/icons';
import { useUpload } from './UploadContext';

const UploadDialog = ({ isOpen, onClose, handleFileChange, handleUpload, removeFile }) => {
  const { selectedFiles, setSelectedFiles, fileType, setFileType, url, setUrl, tabIndex, setTabIndex } = useUpload();
  const [isProcessing, setIsProcessing] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const toast = useToast();

  const onDrop = useCallback((acceptedFiles) => {
    handleFileChange({ target: { files: acceptedFiles } });
  }, [handleFileChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/json': ['.json'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/plain': ['.txt'],
      'text/html': ['.html'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    maxSize: 52428800 // 50MB file size limit
  });

  const handleUploadClick = async () => {
    setIsProcessing(true);
    try {
      const response = await handleUpload();
      if (response.error) {
        setUploadError(response.error);
        toast({
          title: 'Upload Error',
          description: response.error,
          status: 'error',
          duration: 4000,
          isClosable: true
        });
      }
    } catch (error) {
      console.error("Upload error:", error);
      setUploadError('An unexpected error occurred. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const isYouTubeUrl = (url) => {
    return url.includes('youtube.com/watch') || url.includes('youtu.be/');
  };

  const handleUrlValidation = (e) => {
    const inputUrl = e.target.value;
    setUrl(inputUrl);

    if (fileType === 'youtube' && !isYouTubeUrl(inputUrl)) {
      setUploadError('Please enter a valid YouTube URL');
    } else if (fileType === 'web' && isYouTubeUrl(inputUrl)) {
      setUploadError('YouTube URLs are not allowed in the Website field');
    } else {
      setUploadError(''); // Reset error
    }
  };

  useEffect(() => {
    if (uploadError) {
      toast({
        title: "Error",
        description: uploadError,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setUploadError('');
    }
  }, [uploadError, toast]);

  useEffect(() => {
    // Reset states when tab changes
    if (tabIndex === 0) {
      setUrl('');
      setFileType('');
    } else {
      setSelectedFiles([]);
    }
  }, [tabIndex, setFileType, setSelectedFiles, setUrl]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="#232121" color="white">
        <ModalHeader>Upload Item</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs variant='enclosed' onChange={(index) => setTabIndex(index)}>
            <TabList>
              <Tab _selected={{ color: 'white', bg: 'red.600' }}>File Upload</Tab>
              <Tab _selected={{ color: 'white', bg: 'red.600' }}>URL Upload</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <FormControl>
                  <FormLabel>File</FormLabel>
                  <Box
                    {...getRootProps()}
                    border="2px dashed"
                    borderColor={isDragActive ? 'red.300' : 'gray.300'}
                    padding={4}
                    textAlign="center"
                    cursor="pointer"
                    borderRadius="md"
                    bg="#2e2e2e"
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <Text>Drop the files here...</Text>
                    ) : (
                      <Text>Drag 'n' drop some files here, or click to select files</Text>
                    )}
                  </Box>
                  {selectedFiles.length > 0 && (
                    <Box mt={4}>
                      {selectedFiles.map((file, index) => (
                        <Box
                          key={index}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          p={2}
                          border="1px solid"
                          borderColor="gray.300"
                          borderRadius="md"
                          mt={2}
                          bg="#2e2e2e"
                        >
                          <Text>{file.name} - {(file.size / 1024 / 1024).toFixed(2)} MB</Text>
                          <IconButton
                            icon={<CloseIcon />}
                            onClick={() => removeFile(index)}
                            size="sm"
                            variant="ghost"
                            aria-label="Remove file"
                            color="white"
                            _hover={{ color: "red.600" }}
                          />
                        </Box>
                      ))}
                    </Box>
                  )}
                </FormControl>
              </TabPanel>
              <TabPanel>
                <FormControl>
                  <FormLabel>URL</FormLabel>
                  <Input
                    placeholder="Enter URL"
                    value={url}
                    onChange={handleUrlValidation} // URL validation based on selection
                    bg="#2e2e2e"
                    color="white"
                    _placeholder={{ color: 'gray.400' }}
                  />
                  <Select
                    mt={4}
                    placeholder="Select type"
                    value={fileType}
                    onChange={(e) => {
                      setFileType(e.target.value);
                    }}
                    bg="#2e2e2e"
                    color="white"
                  >
                    <option value="web">Website</option>
                    <option value="youtube">YouTube</option>
                  </Select>
                </FormControl>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose} color="white" _hover={{ bg: "gray.800" }}>Cancel</Button>
          <Button colorScheme="red" onClick={handleUploadClick} ml={3} isLoading={isProcessing} isDisabled={!!uploadError}>
            {tabIndex === 0 ? 'Upload' : 'Process'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadDialog;
