// src/components/CreateKnowledgeBaseDialog.js

import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  useToast,
  FormErrorMessage,
} from '@chakra-ui/react';
import { createPublicCollection, createPrivateCollection } from '../apiService';

const CreateKnowledgeBaseDialog = ({ isOpen, onClose, fetchCollections }) => {
  const [collectionName, setCollectionName] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // For loading state
  const [error, setError] = useState('');
  const toast = useToast();

  const handleCreate = async () => {
    const trimmedName = collectionName.trim();
    if (!trimmedName) {
      setError('Collection name cannot be blank.');
      return;
    }

    try {
      setIsSubmitting(true);
      const payload = { collection_name: trimmedName, public: isPublic };
      if (isPublic) {
        await createPublicCollection(payload);
      } else {
        await createPrivateCollection(payload);
      }
      toast({
        title: 'Collection created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchCollections();
      onClose();
      setCollectionName('');
      setIsPublic(false);
      setError('');
    } catch (error) {
      setError(error.message || 'Error creating collection.');
      toast({
        title: 'Error creating collection',
        description: error.message || "An error occurred while creating the collection.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    if (!isSubmitting) {
      onClose();
      setCollectionName('');
      setIsPublic(false);
      setError('');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent bg="linear-gradient(145deg, #2e2e2e, #1e1e1e)" color="white">
        <ModalHeader>Create Knowledge Base</ModalHeader>
        <ModalCloseButton disabled={isSubmitting} />
        <ModalBody>
          <FormControl isInvalid={error}>
            <FormLabel>Collection Name</FormLabel>
            <Input
              value={collectionName}
              onChange={(e) => {
                setCollectionName(e.target.value);
                if (error) setError('');
              }}
              bg="#232121"
              color="white"
              _placeholder={{ color: 'gray.400' }}
            />
            {error && <FormErrorMessage>{error}</FormErrorMessage>}
          </FormControl>
          <FormControl mt={4}>
            <Checkbox
              isChecked={isPublic}
              onChange={(e) => setIsPublic(e.target.checked)}
              colorScheme="red"
            >
              Make Public
            </Checkbox>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={handleClose} color="white" _hover={{ bg: "gray.800" }} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={handleCreate}
            ml={3}
            isLoading={isSubmitting}
            loadingText="Creating"
            disabled={!collectionName.trim()}
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateKnowledgeBaseDialog;
