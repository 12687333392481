import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Card,
  CardHeader,
  CardBody,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import {
  getBotTokens,
  searchWithBotToken,
  deleteBotToken,
  getBotTokenDetails,
  generateEmbedCode,
  deleteEmbedCode,
  fetchEmbedCode,
} from "../apiService"; // Replace with appropriate API calls
import BotsList from "./BotsList"; // Custom list for bots
import MessagesDisplay from "./Conversation/MessagesDisplay"; // Reuse this for showing messages
import MessageInput from "./Conversation/MessageInput"; // Input for sending queries
import BotDialog from "./BotDialog"; // Bot update/create dialog

const Bots = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [bots, setBots] = useState([]);
  const [selectedBotToken, setSelectedBotToken] = useState(null);
  const [selectedBotDetails, setSelectedBotDetails] = useState(null); // Holds the bot details such as collection IDs
  const [isSending, setIsSending] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false); // For create/edit bot dialog
  const [isEditMode, setIsEditMode] = useState(false); // To track if it's edit mode
  const toast = useToast();

  useEffect(() => {
    fetchBots();
  }, []);

  // Fetch all bot tokens and details
  const fetchBots = async () => {
    try {
      const response = await getBotTokens();
      setBots(response.data.tokens);
    } catch (error) {
      console.error("Error fetching bots:", error);
      toast({
        title: "Error",
        description: "Failed to fetch bots.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchBotDetails = async (bot_token) => {
    try {
      const response = await getBotTokenDetails(bot_token);
      setSelectedBotDetails(response.data.bot_details); // Note: Adjusted based on API response
      setSelectedBotToken(bot_token);
      setMessages([]); // Clear previous messages

      // If you want to display the welcome message immediately
      if (response.data.bot_details.welcome_message) {
        setMessages([
          {
            content: response.data.bot_details.welcome_message,
            role: "bot",
          },
        ]);
      }
    } catch (error) {
      console.error("Error fetching bot details:", error);
    }
  };

  // Open the bot dialog for creating a new bot
  const handleNewBot = () => {
    setIsEditMode(false); // Create mode
    setIsDialogOpen(true); // Open dialog
  };

  // Open the bot dialog for editing an existing bot
  const handleEditBot = (bot_token) => {
    fetchBotDetails(bot_token);
    setIsEditMode(true); // Edit mode
    setIsDialogOpen(true); // Open dialog
  };

  // Send message/query to the bot and retrieve response
  const handleSendMessage = async () => {
    if (
      currentMessage.trim() &&
      selectedBotToken &&
      selectedBotDetails?.collection_ids
    ) {
      setIsSending(true);
      const userMessage = { content: currentMessage, role: "user" };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setCurrentMessage("");

      try {
        const response = await searchWithBotToken({
          query: currentMessage.trim(),
          bot_token: selectedBotToken,
        });

        setIsTyping(true);
        setTimeout(() => {
          const botMessage = {
            content: response.data.response || "No response from bot",
            role: "bot",
          };
          setMessages((prevMessages) => [...prevMessages, botMessage]);
          setIsTyping(false);
          setIsSending(false);
        }, 1000);
      } catch (error) {
        console.error("Error sending message:", error);
        setIsSending(false);
        setIsTyping(false);
      }
    }
  };

  // Toggle sidebar collapse
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  // Handle bot deletion
  const handleDeleteBot = async (bot_token) => {
    try {
      await deleteBotToken(bot_token);
      setBots((prevBots) =>
        prevBots.filter((bot) => bot.bot_token !== bot_token)
      );
      setMessages([]);
      setSelectedBotToken(null);
      toast({
        title: "Bot deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting bot:", error);
      toast({
        title: "Error",
        description: "Failed to delete bot.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Handle embed code generation
  const handleGenerateEmbedCode = async (botToken, setEmbedCode) => {
    try {
      const domain = window.location.origin; // Get the UI domain dynamically
      const response = await generateEmbedCode({
        bot_token: botToken,
        conversation_page_url: domain, // Pass the domain dynamically
      });

      // Ensure that the embed code is set in the state properly
      if (response.data.embed_code) {
        setEmbedCode(response.data.embed_code); // Update state with the embed code
      } else {
        throw new Error("Failed to generate embed code");
      }
    } catch (error) {
      console.error("Error generating embed code:", error);
      toast({
        title: "Error",
        description: "Failed to generate embed code.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Handle embed code deletion
  const handleDeleteEmbedCode = async (botToken, onSuccess) => {
    try {
      await deleteEmbedCode(botToken);
      onSuccess(); // Reset the embed code in the modal
      toast({
        title: "Embed code deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting embed code:", error);
      toast({
        title: "Error",
        description: "Failed to delete embed code.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      height="calc(100vh - 3.6rem)"
      paddingTop="4.1rem"
      backgroundColor="#232121"
      overflow="hidden"
    >
      <BotsList
        bots={bots}
        fetchBotConversation={fetchBotDetails}
        handleDeleteBot={handleDeleteBot}
        handleEditBot={handleEditBot}
        handleNewBot={handleNewBot}
        collapsed={collapsed}
        handleGenerateEmbedCode={handleGenerateEmbedCode}
        handleDeleteEmbedCode={handleDeleteEmbedCode}
        fetchEmbedCode={fetchEmbedCode} // Pass the fetchEmbedCode function as a prop
      />

      <Box flex="1" padding={4}>
        <Card height="100%" bg="#232121" color="white">
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <IconButton
                onClick={toggleCollapse}
                icon={collapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />}
                variant="outline"
                aria-label="Toggle sidebar"
                color="white"
                bg="rgb(0 0 0 / 17%)"
                _hover={{ bg: "gray.800" }}
              />
              {selectedBotToken
                ? `Bot: ${selectedBotDetails?.bot_name}`
                : "Select a Bot"}
            </Flex>
          </CardHeader>
          <CardBody display="flex" flexDirection="column" overflowY="auto">
            <MessagesDisplay messages={messages} isTyping={isTyping} />
            {selectedBotToken && (
              <MessageInput
                currentMessage={currentMessage}
                setCurrentMessage={setCurrentMessage}
                handleSendMessage={handleSendMessage}
                isSending={isSending}
              />
            )}
          </CardBody>
        </Card>
      </Box>
      <BotDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        isEditMode={isEditMode}
        selectedBotDetails={selectedBotDetails}
        fetchBots={fetchBots} // Refetch bot list after update or create
      />
    </Flex>
  );
};

export default Bots;
