// CollectionSelector.js
import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  VStack,
  Text,
  useOutsideClick,
} from "@chakra-ui/react";

const CollectionSelector = ({
  availableCollections,
  selectedCollections,
  setSelectedCollections,
  placeholder = "Search collections",
  isInvalid = false,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef();

  useOutsideClick({
    ref: ref,
    handler: () => setShowDropdown(false),
  });

  const handleSelectCollection = (collection) => {
    if (
      !selectedCollections.some(
        (c) => c.collection_id === collection.collection_id
      )
    ) {
      setSelectedCollections([...selectedCollections, collection]);
    }
    setSearchTerm("");
    setShowDropdown(false);
  };

  const handleRemoveCollection = (collectionId) => {
    setSelectedCollections(
      selectedCollections.filter((c) => c.collection_id !== collectionId)
    );
  };

  const filteredCollections = availableCollections.filter(
    (collection) =>
      collection.collection_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) &&
      !selectedCollections.some(
        (c) => c.collection_id === collection.collection_id
      )
  );

  return (
    <Box ref={ref} position="relative">
      <Input
        placeholder={placeholder}
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setShowDropdown(true);
        }}
        onFocus={() => setShowDropdown(true)}
        bg="#1c1c1c"
        color="white"
        _placeholder={{ color: "gray.400" }}
        border={isInvalid ? "1px solid red" : "none"}
      />
      {showDropdown && (
        <Box
          position="absolute"
          top="100%"
          left="0"
          right="0"
          bg="#1c1c1c"
          border="1px solid #4A5568" // Gray.700
          borderRadius="md"
          maxHeight="200px"
          overflowY="auto"
          zIndex="10"
        >
          {filteredCollections.length === 0 ? (
            <Text p={2} color="gray.400">
              No collections found
            </Text>
          ) : (
            filteredCollections.map((collection) => (
              <Box
                key={collection.collection_id}
                p={2}
                cursor="pointer"
                _hover={{ bg: "gray.700" }}
                onClick={() => handleSelectCollection(collection)}
              >
                {collection.collection_name}
              </Box>
            ))
          )}
        </Box>
      )}
      <VStack
        mt={2}
        spacing={2}
        align="start"
        maxHeight="100px"
        overflowY="auto"
      >
        {selectedCollections.map((collection) => (
          <Tag
            size="md"
            key={collection.collection_id}
            borderRadius="full"
            variant="solid"
            colorScheme="red"
          >
            <TagLabel>{collection.collection_name}</TagLabel>
            <TagCloseButton
              onClick={() => handleRemoveCollection(collection.collection_id)}
            />
          </Tag>
        ))}
      </VStack>
    </Box>
  );
};

export default CollectionSelector;
